<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="">
		<van-pull-refresh class="notice" v-model="fresh" @refresh="onRefresh">
			<van-list offset="10" v-if="noticeList.length > 0&&!showEmpty" v-model="loadMore" :finished="loadFinish"
				finished-text="没有更多了" @load="scrollToLower">
				<div class="notice-list">
					<div @click="toDetail(item)" v-for="(item, index) in noticeList" :key="index"
						class="notice-list-item">
						<div class="list-item-time">
							{{item.createTime}}
						</div>
						<div class="list-item-content">
							<div class="item-content-title">
								<span style="position:relative">{{item.title}} <span class="circle" v-if="item.read==false"></span></span>
								<img src="@/assets/images/mobile/common/arrow-right.png" alt="">
							</div>
							<div class="item-content-val" v-html="item.content">
							</div>
						</div>
					</div>
				</div>
			</van-list>
			<div v-if="noticeList.length == 0&&showEmpty" class="notice-empty">
				<img src="@/assets/images/mobile/common/empty1.png" alt="">
				<span>暂无公告</span>
			</div>
		</van-pull-refresh>
	</div>
</template>
<script>
	import {
		noticeListMb
	} from "@/api/mobile/notice";
	
	import {
		messageUnreadClearMb
	} from "@/api/mobile/mine";
	export default {
		data() {
			return {
				noticeList: [
					// 	{
					// 	title: "公告标题1",
					// 	content: "公告内容展示公告内容展示公告内容展示公告内容展示",
					// 	time: "2022-06-21 10:21"
					// }, {
					// 	title: "公告标题2",
					// 	content: "公告内容展示公告内容展示公告内容展示公告内容展示",
					// 	time: "2022-06-21 10:21"
					// },
				],
				pageIndex: 1,
				pageSize: 6,
				totalCount: 0,
				fresh: false,
				loadMore: false,
				loadFinish: false,
				showEmpty: false,
			}
		},
		mounted() {
			this.getNoticeData()
		},
		methods: {
			//获取公告数量
			onRefresh() {
				this.pageIndex = 1
				setTimeout(() => {
					this.noticeList = []
					this.getNoticeData()
				}, 1000);
			},
			scrollToLower() {
				// 加载状态结束
				if (this.totalCount > this.pageSize && this.pageIndex > 1) {
					this.getNoticeData()
				}
			},
			getNoticeData() {
				return new Promise((resolve, reject) => {
					this.loadFinish = false
					this.showEmpty = false
					let params = {
						pageIndex: this.pageIndex,
						pageSize: this.pageSize
					}
					noticeListMb(params).then((res) => {
						// console.log("getNoticeData", res)
						if (res.code == 0) {
							if (this.pageIndex == 1) {
								this.fresh = false;
							}
							this.loadMore = false
							res.data.forEach(item => {
								this.$set(item, "status", 1)
							})
							this.noticeList = this.noticeList.concat(res.data)
							this.totalCount = res.page.totalCount
							if (this.noticeList.length >= res.page.totalCount * 1) {
								this.loadFinish = true
							}
							if (this.noticeList.length == 0) {
								this.showEmpty = true
							}
							this.pageIndex++
							resolve()
						} else {
							this.loadMore = false
							this.loadFinish = true
							this.fresh = false
							this.showEmpty = false
							reject()
						}
					}).catch((err) => {
						this.loadMore = false
						this.loadFinish = true
						this.fresh = false
						this.showEmpty = false
						reject()
					})
				})
			},
			toDetail(item) {
				this.$router.push({
					path: `/mb/notice/detail?noticeId=${item.id}`
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	/deep/p {
		padding: 0 0;
		margin: 0 0;
		max-height: 0.8rem;
		overflow: hidden;
		display: -webkit-box;
		/*设置为弹性盒子*/
		-webkit-line-clamp: 2;
		/*最多显示3行*/
		overflow: hidden;
		/*超出隐藏*/
		text-overflow: ellipsis;
		/*超出显示为省略号*/
		-webkit-box-orient: vertical;
		word-break: break-all;
	}

	.notice {
		height: 100%;
		display: flex;
		flex-direction: column;

		.notice-list {
			display: flex;
			flex-direction: column;

			.notice-list-item {
				display: flex;
				flex-direction: column;

				.list-item-time {
					height: 0.78rem;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 0.24rem;
					font-family: PingFang SC;
					font-weight: 400;
					color: #B4BCC6;
				}

				.list-item-content {
					background: #FFFFFF;
					padding: 0.28rem 0.28rem;
					box-sizing: border-box;
					display: flex;
					flex-direction: column;

					.item-content-title {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-bottom: 0.16rem;
						border-bottom: 0.02rem solid #DCDFE6;
						.circle{
							width: 0.12rem;
							height: 0.12rem;
							background: #E82D2D;
							border-radius:50%;
							position:absolute;
							top:0;
							left:0;
							z-index:999999;
						}
						span {
							font-size: 0.28rem;
							font-family: PingFang SC;
							font-weight: bold;
							height: 0.3rem;
							color: #303133;
							overflow: hidden;
							display: -webkit-box;
							/*设置为弹性盒子*/
							-webkit-line-clamp: 1;
							/*最多显示3行*/
							overflow: hidden;
							/*超出隐藏*/
							text-overflow: ellipsis;
							/*超出显示为省略号*/
							-webkit-box-orient: vertical;
							word-break: break-all;
						}

						img {
							width: 0.32rem;
							height: 0.32rem;
						}
					}

					.item-content-val {
						min-height: 0.4rem;
						font-size: 0.28rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.4rem;
						color: #606266;
						margin-top: 0.16rem;
						word-break: break-all;

					}

				}
			}
		}

		.notice-empty {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 2rem 0;

			img {
				width: 2.8rem;
				height: 2.8rem;
				margin-bottom: 0.4rem;
			}

			span {
				font-size: 0.28rem;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #909399;
				line-height: 0.4rem;
			}
		}
	}
</style>
